import React, { useState } from 'react';
import Media from 'react-responsive'

import Navbar from '../components/general/navbar/Navbar'
import Footer from '../components/general/Footer'
import MobileNavbar from '../components/mobile/MobileNavbar'
import MobileFooter from '../components/mobile/MobileFooter'

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Email sent:', data);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div className='relative top-0'>
        <Media query="(max-width: 768px)">
        {isMobile => (
          isMobile ? <MobileNavbar /> : <Navbar absolute={false} color='navy' />
        )}
      </Media>
    {/* <div className="max-w-md mx-auto flex flex-col p-4 font-display">
      <h2 className="text-2xl font-bold text-center text-navy mb-4">Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-600">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-navy-300"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-600">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-600">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="subject" className="block text-gray-600">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-600">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
            rows="4"
            required
          ></textarea>
        </div>
        <div className='justify-center'>
        <button
          type="submit"
          className="bg-navy text-white px-4 py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300"
        >
          Submit
        </button>
          
        </div>
        
      </form>
      <div>
        <br />
        <br />
        <br />
      </div>
    </div> */}
    <div className='flex flex-col justify-center items-center font-display font-bold text-navy h-3/4'>
      <br />
      <br />
      <br />
      <br />
      <br />
            <img src={require('../images/50yrs.png')} 
                className='w-1/5 object-contain'/>
            <div className='text-center'>
                <br />
                <div className='font-regular text-2xl'>
                  <br />
              If you would like to contact us, please email <a href="mailto:hr@masseysglass.com">
          <span className='font-bold underline'> hr@masseysglass.com </span>
          </a>
            </div>
            {/* <br />
                This page is under construction! 
                <br />
                Please come back later. */}
            </div>
            
        </div>
        <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    <Media query="(max-width: 768px)">
        {isMobile => (
          isMobile ? <MobileFooter /> : <Footer />
        )}
      </Media>
    </div>
  );
};

export default Contact;
