import React from 'react'

const HomeLogo = () => {
  return (
    <div>
      <img src={require('../../../images/logotitle.png')}/>
    </div>
  )
}

export default HomeLogo