import React from 'react'
import ProjectSquare from './MobileProjectSquare'

const MobileProjects = () => {
  return (
    <div>
        <div className='flex flex-col items-center space-y-4'>
  <ProjectSquare imageUrl={require('../../images/105westst.png')}
    name='105 West 1st Street' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/380stuart.jpeg')}
    name='380 Stuart Street' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/88seaportalt.jpeg')}
    name='88 Seaport' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/888boylstonshot.png')}
    name='888 Boylston Street' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/backbaysouth.jpeg')}
    name='Back Bay South End Gateway' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/bostonmed.jpeg')}
    name='Boston Medical' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/budentalmed.jpeg')}
    name='Boston University School of Dental Medicine' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/brighamwomens.jpeg')}
    name="Brigham & Women's Hospital" loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/bulfinchxing.png')}
    name='Bulfinch Crossing' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/eataly.jpeg')}
    name='Eataly' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/fenwayphaseii.jpeg')}
    name="Fenway Center Phase II" loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/harvarderc.png')}
    name='Harvard ERC East & West Labs' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/massgeneral.png')}
    name='Mass General Hospital' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/massmutual.jpeg')}
    name="Mass Mutual @ Fan Pier" loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/pier4.jpg')}
    name='Pier 4' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/22liberty.jpeg')}
    name='Twenty Two Liberty @ Fan Pier' loc='Boston, MA'/>
  <ProjectSquare imageUrl={require('../../images/150second.jpeg')}
    name="150 2nd Street" loc='Cambridge, MA'/>
  <ProjectSquare imageUrl={require('../../images/binney.png')}
    name='290/250 Binney Street' loc='Cambridge, MA'/>
  <ProjectSquare imageUrl={require('../../images/googlehq.png')}
    name='Google Headquarters' loc='Cambridge, MA'/>
  <ProjectSquare imageUrl={require('../../images/mitsite5.jpeg')}
    name="MIT Site 5" loc='Cambridge, MA'/>
  <ProjectSquare imageUrl={require('../../images/ragon.jpg')}
    name='Ragon Institute II' loc='Cambridge, MA'/>
  <ProjectSquare imageUrl={require('../../images/uriengineering.jpeg')}
    name='URI College of Engineering' loc='South Kingston, RI'/>
  <ProjectSquare imageUrl={require('../../images/danbury.png')}
    name="Danbury Hospital" loc='Danbury, CT'/>
  <ProjectSquare imageUrl={require('../../images/qwellness.jpeg')}
    name='Quinnipiac University Wellness Center' loc='Hamden, CT'/>
  <ProjectSquare imageUrl={require('../../images/foxwoodsresort.jpeg')}
    name='Foxwoods Resort Hotel & Casino' loc='Ledyard, CT'/>
  <ProjectSquare imageUrl={require('../../images/mgmgrand.jpeg')}
    name="MGM Grand at Foxwoods" loc='Ledyard, CT'/>
  <ProjectSquare imageUrl={require('../../images/100college.jpeg')}
    name='100 College St' loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/101college.jpeg')}
    name='101 College St' loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/55park.jpeg')}
    name="55 Park Street" loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/knightsofcolumbus.jpeg')}
    name='Knights of Columbus' loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/yalenewhaven.jpeg')}
    name='Yale New Haven Hospital' loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/yalepeabody.jpeg')}
    name="Yale Peabody Museum" loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/yalesmilow.jpeg')}
    name='Yale Smilow Cancer Center' loc='New Haven, CT'/>
  <ProjectSquare imageUrl={require('../../images/norwalkhospital.jpeg')}
    name='Norwalk Hospital' loc='Norwalk, CT'/>
  <ProjectSquare imageUrl={require('../../images/b&h.jpeg')}
    name="Brown University School of Engineering" loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/friarcenter.jpeg')}
    name='Providence College Friar Center' loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/pcbusiness.jpeg')}
    name='Providence College School of Business' loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/pvdplace.jpeg')}
    name="Providence Place Mall" loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/ssl.jpeg')}
    name='South Street Landing' loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/dunk.jpeg')}
    name="The Dunkin' Donuts Center" loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/womeninfants.jpeg')}
    name="Women & Infants Hospital" loc='Providence, RI'/>
  <ProjectSquare imageUrl={require('../../images/bryantbello.jpeg')}
    name='Bryant University George E. Bello Center' loc='Smithfield, RI'/>
  <ProjectSquare imageUrl={require('../../images/stamfordhospital.jpeg')}
    name="Stamford Hospital" loc='Stamford, CT'/>
  <ProjectSquare imageUrl={require('../../images/uconngant.jpeg')}
    name="UCONN Gant Renovation" loc='Storrs, CT'/>
  <ProjectSquare imageUrl={require('../../images/uconnstem.jpeg')}
    name='UCONN Stem Research Center' loc='Storrs, CT'/>
  <ProjectSquare imageUrl={require('../../images/moheganearth.jpeg')}
    name="Mohegan Sun Earth Hotel" loc='Uncasville, CT'/>
  <ProjectSquare imageUrl={require('../../images/mohegansky.jpeg')}
    name="Mohegan Sun Tower Hotel" loc='Uncasville, CT'/>
  <ProjectSquare imageUrl={require('../../images/whiteplainshospital.jpeg')}
    name='White Plains Hospital Medical Center' loc='White Plains, NY'/>
</div>


    </div>
  )
}

export default MobileProjects