import React from 'react'

const Logo = () => {
  return (
    <div>
      <img src={require('../../../images/logotitlenavy.png')}/>
    </div>
  )
}

export default Logo